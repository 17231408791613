<template>
  <div class="strategy">
    <Navigation :path='path' />
    <h2>ENTERPRISE</h2>
    <div class="">
      <div class="pc-content hidden-xs-only">
        <div class="left">
          <h4>国际化战略部署</h4>
          <p>我们立志让每一个孩子都能拥有改变世界的决心和能力，为中国家庭提供更优质更国际化的教育资源</p>
        </div>
        <div class="right">
          <p>亚洲布局</p>
          <img src="~img/strategy/01.png">
        </div>
      </div>
      <div class="web-content hidden-sm-and-up">
        <div class="left">
          <h4>国际化战略部署</h4>
          <p>我们立志让每一个孩子都能拥有改变世界的决心和能力，为中国家庭提供更优质更国际化的教育资源</p>
        </div>
        <div class="right">
          <p>亚洲布局</p>
          <img src="~img/strategy/01.png">
        </div>
      </div>
      <div class="address clearfix">
        <div class="list hidden-xs-only">
          <div class="seach">
            <div class="input-box">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
              <el-button icon="el-icon-search" @click="seach"></el-button>
            </div>
            <ul>
              <li v-for="item in list" :key="item.id" @click="changMap(item)" :class="{active:item.id == id}">
                <p class="p1">校区名称：{{item.name}}</p>
                <p class="p2">校区地址：{{item.address}}</p>
                <p class="p3">联系电话：{{item.tel}}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="web-list hidden-sm-and-up">
          <van-tabs color='#2878ff' class="hidden-sm-and-up" @click="webTaggles">
            <van-tab v-for="item in list" :key="item.id" :title="item.name" :name="item.id">
            </van-tab>
          </van-tabs>
        </div>
        <div class="addresbtn" @click="showMap = true" :class="{current:showMap}"> <i
            class="el-icon-location-outline"></i> 查看位置 </div>
        <div class="clasbtn" @click="showMap = false" :class="{current:!showMap}">校区介绍</div>
        <div class="guide">
          <div id="map" v-show="showMap"></div>
          <div class="schools">
            <img :src="image" v-show="!showMap">
            <img src="~img/strategy/02.png" class="btn-img" v-show="!showMap" @click="infoImg">
          </div>
        </div>
      </div>
    </div>
    <div class="hidden-sm-and-up">

    </div>
    <consulting />
    <foot />

  </div>
</template>

<script>
import Navigation from "@/components/header.vue";
import "element-ui/lib/theme-chalk/display.css";
import footer from "@/components/footer.vue";
import consulting from "@/components/consulting.vue";
export default {
  components: {
    Navigation,
    foot: footer,
    consulting,
  },
  data() {
    return {
      path: "/strategy",
      input: "",
      list: [],
      id: "",
      showMap: true,
      image: "",
      name: "",
      tel: "",
    };
  },
  created() {},
  mounted() {
    this.getMap();
  },
  methods: {
    infoImg() {
      this.$router.push({ path: `/infoimg?id=${this.id}` });
      localStorage.setItem(
        "address",
        JSON.stringify({ name: this.name, tel: this.tel })
      );
    },
    webTaggles(name) {
      console.log(name);
      let list = this.list.filter((value) => {
        return value.id == name;
      });
      let x = list[0].longitude;
      let y = list[0].latitude;
      let address = list[0].address;
      this.initMap(x, y, address);
    },
    seach() {
      this.list = this.list.filter((value) => {
        return value.name == this.input;
      });
      let x = this.list[0].longitude;
      let y = this.list[0].latitude;
      let address = this.list[0].address;
      this.initMap(x, y, address);
    },
    async getMap() {
      const res = await this.axios.get("api/index/map");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.list = data;
        let x = this.list[0].longitude;
        let y = this.list[0].latitude;
        let address = this.list[0].address;
        this.image = this.list[0].image;
        this.id = this.list[0].id;
        this.name = this.list[0].name;
        this.tel = this.list[0].tel;
        this.initMap(x, y, address);
      }
    },
    changMap(value) {
      this.initMap(value.longitude, value.latitude, value.address);
      this.id = value.id;
      this.image = value.image;
      this.showMap = true;
      this.name = value.name;
      this.tel = value.tel;
      console.log(this.name, this.tel);
    },
    initMap(x, y, address) {
      // 百度地图API功能
      var map = new BMap.Map("map"); // 创建Map实例
      var point = new BMap.Point(x, y); // 创建点坐标
      map.centerAndZoom(point, 18); // 初始化地图，设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var opts = {
        width: 150, // 信息窗口宽度
        height: 10, // 信息窗口高度
        title: "位置", // 信息窗口标题
      };
      var infoWindow = new BMap.InfoWindow(address, opts); // 创建信息窗口对象
      map.openInfoWindow(infoWindow, map.getCenter()); // 打开信息窗口
    },
  },
};
</script>

<style lang="less" scoped>
.strategy {
  h2 {
    font-size: 0.38rem;
    color: #000000;
    text-align: center;
    line-height: 100%;
    padding-top: 1.4rem;
  }
  .pc-content {
    display: flex;
    width: 67%;
    margin: auto;
    margin-top: 0.4rem;
    .left {
      width: 35%;
      padding-top: 0.6rem;
      h4 {
        font-size: 0.25rem;
        color: #1e1e1e;
        text-align: center;
      }
      p {
        width: 50%;
        margin: 0.5rem auto 0 auto;
        text-indent: 0.36rem;
        font-size: 0.18rem;
        color: #000000;
        line-height: 150%;
      }
    }
    .right {
      width: 65%;
      img {
        width: 100%;
      }
      p {
        font-size: 0.3rem;
        text-align: center;
        margin-bottom: 0.3rem;
      }
    }
  }
  .web-content {
    .left {
      width: 90%;
      margin: auto;
      padding-top: 0.2rem;
      h4 {
        font-size: 0.3rem;
        color: #1e1e1e;
        text-align: center;
      }
    }
    .right {
      width: 90%;
      margin: auto;
      padding-top: 0.2rem;
      p {
        text-align: center;
        font-size: 0.3rem;
        margin-bottom: 0.2rem;
      }
    }
  }
  .web-list {
    padding-top: 20px;
    .web-guide {
      width: 80%;
      margin: auto;
      #map {
        width: 100%;
        height: 8rem;
      }
    }
  }
  .address {
    width: 72%;
    margin: auto;
    margin-top: 2rem;
    padding-bottom: 1rem;
    // height: 9rem;
    .guide {
      float: right;
      padding: 0.2rem;
      border-radius: 0.15rem;
      border: 0.01rem solid #eee;
      width: 76%;
      #map {
        width: 100%;
        height: 8rem;
      }
      .schools {
        position: relative;
        .btn-img {
          position: absolute;
          width: 3.19rem;
          bottom: 0.4rem;
          left: 50%;
          transform: translate(-50%);
          cursor: pointer;
        }
      }
    }
    .addresbtn {
      float: left;
      width: 2rem;
      height: 0.5rem;
      font-size: 0.25rem;
      border: solid 1px #ccc;
      text-align: center;
      line-height: 0.5rem;
      margin-left: 0.18rem;
      margin-bottom: 0.1rem;
      border-radius: 10px 0 0 0;
      cursor: pointer;
    }
    .clasbtn {
      width: 2rem;
      height: 0.5rem;
      float: left;
      font-size: 0.25rem;
      border: solid 1px #ccc;
      text-align: center;
      line-height: 0.5rem;
      border-radius: 0 10px 0 0;
      cursor: pointer;
    }
    .current {
      background: #000;
      color: #fff;
    }
    .list {
      width: 23%;
      float: left;
      .seach {
        .el-input {
          width: 80%;
        }
        .input-box {
          display: flex;
        }
      }
      ul {
        height: 8rem;
        overflow-y: auto;
        li {
          margin-top: 0.3rem;
          line-height: 0.3rem;
          font-size: 0.16rem;
          cursor: pointer;

          .p2 {
            color: #999999;
          }
          .p3 {
            color: #999999;
          }
        }
        .active {
          border: 1px solid #eee;
        }
      }
    }
  }
  @media (max-width: 750px) {
    .address {
      width: 90%;
      .guide {
        float: none;
        width: 100%;
      }
      li {
        width: 80%;
        margin: auto;
        margin-bottom: 20px;
      }
    }
  }
}
</style>